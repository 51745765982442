import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';

import { UserContext } from '../context/UserContext';
import spinner from '../images/spinner.gif'

const SuperDashBord = () => {

    const { user, apiUrlRoot } = useContext(UserContext);
    const [journalData, setJournalData] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [maxMonth, setMaxMonth] = useState('');
    const [isLoading, setIsLoading] = useState('');

    const history = useHistory()


    const navigate = (link) => {
        history.push(link);
    }

    const selectedMonthChangeHandler = (e) => {
        console.log(e.target.value);
        setSelectedMonth(e.target.value);
    }

    useEffect(() => {
        if (selectedMonth) {
            loadData();
        } else {
            setSelectedMonth(getMonth());
            setMaxMonth(getMonth());
        }
    }, [selectedMonth])


    const loadData = () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            },
            body: JSON.stringify({
                'periode': selectedMonth,
            })
        },
            url = apiUrlRoot + "admin/super-admin-dash";

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.code === 201) {
                    setJournalData(result.data);
                } 
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }

    const getMonth = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;

        return [year, month].join('-')
    }


    const Tbody = ({ data }) => {
        // console.log('data', data);
        let i = 1, tr;
        if (data.length > 0) {
            tr = data.map((item) => (
                item.nbCourseDuMois >0 && (
                        <tr key={(i++).toString()}>
                            <td>{i}</td>
                            <td>
                                    <a href="#" onClick={() => { navigate('/entreprise/' + item.id) }}>
                                        {item.nom}
                                    </a>
                            </td>
                            <td>{item.nbCourseDuMois}</td>
                            <td>{item.recetteDuMois} F.cfa</td>
                        </tr>
                    )
               )
            );
        } else {
            tr = (
                <Fragment>
                    <tr>
                        <td colSpan={4}></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan={2} style={{ height: 100 }}> <h4>Aucun resultat</h4></td>
                    </tr>
                </Fragment>
            )
        }
        return (<tbody>{tr}</tbody>)
    }

    return (
        <div className="content-wrapper p-2">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{selectedMonth === getMonth() ? 'Ce mois-ci' : selectedMonth }</h1>
                        </div>
                        <div className="col-sm-3 offset-2">
                            <input
                                type="month"
                                value={selectedMonth}
                                onChange={selectedMonthChangeHandler}
                                max={maxMonth} />
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6">
                            <div className="small-box bg-success">
                                <br/>
                                <div className="inner">
                                    <h1>
                                        {journalData
                                            ? journalData.termine
                                            : (<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                        }
                                &nbsp;<sup style={{ fontSize: 20 }}>Courses</sup>
                                    </h1>
                                    <h4>Réalisées</h4>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag" />
                                </div>
                                {/* <a className="small-box-footer">Voir la liste <i className="fas fa-arrow-circle-right" /></a> */}
                                <br/>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="small-box bg-danger">
                                <br/>
                                <div className="inner">
                                    <h4>Recette du mois</h4>
                                    <h1>
                                        {journalData
                                            ? journalData.recette
                                            : (<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                        }
                                    &nbsp;<sup style={{ fontSize: 20 }}>F.CFA</sup>
                                    </h1>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-cash" />
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <section className="col-lg-6">
                            <div className="card">
                                <div className="card-header bg-warning">
                                    <h3 className="card-title">
                                        # Top 5 Entreprise
                </h3>
                                    <div className="float-right">

                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: 50 }}>rang</th>
                                                <th>Nom</th>
                                                <th >Courses</th>
                                                <th>Commissions</th>
                                            </tr>
                                        </thead>

                                        {journalData
                                            ? <Tbody data={journalData.topEntreprise} />
                                            : (
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src={spinner} alt="Logo" style={{ height: 100 }} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}

                                    </table>
                                </div>
                            </div>
                        </section>
                        <section className="col-lg-6">
                            <div className="card">
                                <div className="card-header bg-info">
                                    <h3 className="card-title">
                                        # Historique des recettes
                </h3>
                                    <div className="float-right">

                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Période</th>
                                                <th>Montant</th>
                                            </tr>
                                        </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src={spinner} alt="Logo" style={{ height: 100 }} />
                                                        </td>
                                                    </tr>
                                                </tbody>

                                    </table>
                                </div>
                            </div>
                        </section>

                    </div>
                    {/* /.row (main row) */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
}

export default SuperDashBord
