import React, { useContext } from 'react'
import { UserContext } from '../context/UserContext';
import { useHistory } from "react-router-dom";
import defaultLogo from '../images/defaultLogo.png'

const SuperMenu = () => {

    const history = useHistory()

    const { user, apiUrlRoot } = useContext(UserContext);

    const navigate = (link) => {
        history.push(link);
    }
    
    

    return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
    {/* Brand Logo */}
    <a onClick={()=>{navigate('/')}} className="brand-link">
        <img src={user.entreprise_logo?apiUrlRoot+user.entreprise_logo:defaultLogo} alt="Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
        <span onClick={()=>{navigate('/')}} className="brand-text font-weight-light">{user.entreprise_nom}</span>
    </a>
    {/* Sidebar */}
    <div className="sidebar">
        {/* Sidebar Menu */}
        <nav className="mt-5">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li className="nav-item">
                    <a className="nav-link" onClick={()=>{navigate('/')}}>
                        <i className="nav-icon fas fa-chart-bar" />
                        <p>
                            Tableau de bord
                        </p>
                    </a>
                </li>
                <li className="nav-item mt-2">
                    <a onClick={()=>{navigate('/entreprise')}} className="nav-link" href="#">
                        <i className="nav-icon fas fa-clipboard-list" />
                        <p>
                            Entreprise
                        </p>
                    </a>
                </li>
                <li className="nav-item mt-2">
                    <a onClick={()=>{navigate('/entreprise/new')}} className="nav-link" href="#">
                        <i className="nav-icon fas fa-caret-right" />
                        <i className="nav-icon fas fa-caret-right" />
                        <p>
                            Création
                        </p>
                    </a>
                </li>
                <li className="nav-item mt-2">
                    <a onClick={()=>{navigate('/entreprise')}} className="nav-link" href="#">
                        <i className="nav-icon fas fa-caret-right" />
                        <i className="nav-icon fas fa-caret-right" />
                        <p>
                            Gestion
                        </p>
                    </a>
                </li>

                <li className="nav-item mt-2">
                    <a className="nav-link" href="#">
                        <i className="nav-icon fas fa-receipt" />
                        <p>
                            Factures
                        </p>
                    </a>
                </li>
                <li className="nav-item mt-2">
                    <a onClick={()=>{navigate('/course/en-cours')}} className="nav-link" href="#">
                        <i className="nav-icon fas fa-caret-right" />
                        <i className="nav-icon fas fa-caret-right" />
                        <p>
                            Création
                        </p>
                    </a>
                </li>
                <li className="nav-item mt-2">
                    <a onClick={()=>{navigate('/course/en-cours')}} className="nav-link" href="#">
                        <i className="nav-icon fas fa-caret-right" />
                        <i className="nav-icon fas fa-caret-right" />
                        <p>
                            Gestion
                        </p>
                    </a>
                </li>
            
            </ul>
        </nav>
        {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
</aside>
    )
}

export default SuperMenu

