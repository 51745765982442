import React, { Fragment, useContext, useEffect, useState } from 'react'
import spinner from '../images/spinner.gif'
import { useHistory } from 'react-router';
import { UserContext } from '../context/UserContext';
import defaultLogo from '../images/defaultLogo.png'

const ConfigurationDisplay = () => {

    const { user, apiUrlRoot, setUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [nom, setNom] = useState();
    const [tel, setTel] = useState();
    const [email, setEmail] = useState();
    const [logoFile, setLogoFile] = useState();
    const [someChanges, setSomeChanges] = useState(false);

    const [tarifs, setTarifs] = useState();
    const [isAddingTarif, setIsAddingTarif] = useState(false);
    const [isDelTarif, setIsDelTarif] = useState();
    const [isEditTarif, setIsEditTarif] = useState();
    const [montant, setMontant] = useState();
    const [alertMsg1, setAlertMsg1] = useState();
    const [radio, setRadio] = useState();

    const history = useHistory()


    useEffect(() => {
        loadTarif();
        setNom(user.entreprise_nom);
        setTel(user.entreprise_tel);
        setEmail(user.entreprise_email)
    }, []);

    const refreshUser = () => {
        setIsLoading3(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        };
        let url = apiUrlRoot+"admin/user/refresh";

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading3(false);
                if (result.access_token) {
                    setUser(result);
                }
            },
                (error) => {
                    setIsLoading3(false);
                    console.error(error)
                }
            )
    }

    const submitFormEntreprise = (e) => {
        e.preventDefault();
        setAlertMsg1('');
        setIsLoading2(true);

        const data = new FormData();
        data.append('logo',logoFile );
        data.append('tel', tel);
        data.append('nom', nom);
        data.append('email', email);
        
        const requestOptions = {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            },
            body: data
        };
        let url = apiUrlRoot+"admin/entreprise/update";

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading2(false);
                setSomeChanges(false);
                if (result.code === 201) {
                    setAlertMsg1('Données mises à jour');
                    // setUser('');
                    refreshUser();
                }
            },
                (error) => {
                    setIsLoading2(false);
                    console.error(error)
                }
            )
    }



    const nomChangeHandle = event => {
        setNom(event.target.value);

        if(event.target.value!== user.entreprise_nom){
            setSomeChanges(true);
        }
    }

    const telChangeHandle = event => {
        setTel(event.target.value);

        if(event.target.value!== user.entreprise_tel){
            setSomeChanges(true);
        }
    }

    const emailChangeHandle = event => {
        setEmail(event.target.value);

        if(event.target.value!== user.entreprise_email){
            setSomeChanges(true);
        }
    }


    const fileChangeHandle = event => {
        setLogoFile(event.target.files[0]);
        // console.log('logoFile', logoFile);
        setSomeChanges(true);
    }


    const submitAddTarifForm = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            },
            body: JSON.stringify({
                'montant': montant,
            })
        };
        let url = '';
        if (isEditTarif) {
            url = apiUrlRoot+"admin/tarif/update/" + isEditTarif;
        } else {
            url = apiUrlRoot+"admin/tarif/new";
        }

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.code === 201) {
                    setTarifs(result.data);
                    annulerSaisieTarif();
                } else if (result.code === 202) {
                    setTarifs('');
                    annulerSaisieTarif();
                }
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }

    const deleteTarif = () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = apiUrlRoot+"admin/tarif/delete/" + isDelTarif;

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.code === 201) {
                    setTarifs(result.data);
                    annulerSaisieTarif();
                } else if (result.code === 202) {
                    setTarifs('');
                    annulerSaisieTarif();
                }
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }

    const loadTarif = () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = apiUrlRoot+"admin/tarif";

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.code === 201) {
                    setTarifs(result.data);
                } else if (result.code === 202) {
                    setTarifs('');
                    annulerSaisieTarif();
                }
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }

    
    const montantChangeHandle = event => {
        setMontant(event.target.value);
    }

    // const onRadioChange = (event) => {
    //     console.log(event.target.value);
    //     setRadio(event.target.value);

    // }

    const annulerSaisieTarif = () => {
        setIsAddingTarif(false);
        setIsEditTarif('');
        setMontant('');

    }

    const editerTarif = (item) => {
        setIsAddingTarif(true);
        setIsEditTarif(item.id);
        setMontant(item.montant);
    }



    const Tbody = ({ data }) => {
        let i = 1, tr;
        if (data && data.length > 0) {
            tr = data.map((item) => (
                <tr key={(i++).toString()}>
                    <td><span className="badge bg-danger">{item.montant} F.CFA</span></td>
                    <td>
                        {isDelTarif !== item.id && (
                            <Fragment>
                                <button onClick={() => { editerTarif(item) }} className="btn btn-primary btn-xs" title="Modifier">
                                    <i className="fas fa-edit" />
                                </button>
                                <button onClick={() => setIsDelTarif(item.id)} className="btn btn-danger btn-xs ml-2" title="Supprimer">
                                    <i className="fas fa-trash" />
                                </button>
                            </Fragment>
                        )}

                        {isDelTarif === item.id && (
                            <Fragment>
                                <button onClick={deleteTarif} className="btn btn-danger btn-sm" title="Confirmer">
                                    <i className="fas fa-check" />
                                </button>
                                <button onClick={() => setIsDelTarif('')} className="btn btn-secondary btn-sm ml-2" title="Annuler">
                                    <i className="fas fa-times" />
                                </button>
                            </Fragment>
                        )}

                    </td>
                </tr>
            )
            );
        } else {
            tr = (
                <tr>
                    <td colSpan={2} style={{ height: 100 }}> <p>La liste est vide </p></td>
                </tr>
            )
        }
        return (<tbody>{tr}</tbody>)
    }

    return (
        <div className="content-wrapper">
            <div className="row ml-3 mt-3">
                <div className="card card-primary col-md-7">
                    <div className="card-header">
                        <h3 className="card-title">Informations de l'entreprise</h3>
                    </div>
                    <div className="card-body">
                        <form onSubmit={submitFormEntreprise}>
                            <div className="row">
                                <div className="form-group  col-7">
                                    <label htmlFor="nom">Nom de l'entreprise <sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nom"
                                        id="nom"
                                        value={nom}
                                        onChange={nomChangeHandle}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-7">
                                    <label htmlFor="nom">Numéro de Téléphone <sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control"
                                        type="tel"
                                        name="tel"
                                        id="tel"
                                        value={tel}
                                        onChange={telChangeHandle}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-7">
                                    <label htmlFor="nom">Adresse Email<sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        id="email"
                                        value={email}
                                        onChange={emailChangeHandle}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-7">
                                    <label htmlFor="telephone">Logo <sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        onChange={fileChangeHandle}
                                    />
                                </div>
                                <div className="col-4">
                                    <img src={user.entreprise_logo?apiUrlRoot+user.entreprise_logo:defaultLogo} style={{ height: 150 }} alt="logo" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                            {alertMsg1 && (
                                <p className="login-box-msg text-success">
                                    <i className="fas fa-check" /> {alertMsg1}
                                </p>
                            )}</div>
                            </div>
                            <div className="row">
                                <div className="col-6 offset-2">
                                    <button type="submit" disabled={!someChanges} className="btn btn-primary btn-block" >
                                        Enregistrer &nbsp;
                                    {isLoading2 && (<img src={spinner} alt="Logo" style={{ height: 30 }} />)}
                                    </button>
                                </div>
                                {/* /.col */}
                            </div>
                        </form>
                    </div>
                    {/* /.card-body */}
                </div>

                <div className="card card-secondary col-md-4 ml-4">
                    <div className="card-header">
                        <h3 className="card-title">Options</h3>
                    </div>
                    <div className="card-body">

                        {/* <form onSubmit={submitForm}> */}
                        {/* <div className="row">
                                <div className="col-5">
                                    <label htmlFor="nom">Sms a l'expéditeur: <sup className="text-danger">*</sup></label>
                                </div>
                                <div className="col-3">
                                    <label>
                                        <input
                                            type="radio"
                                            value="on"
                                            checked={radio === "on"}
                                            onChange={onRadioChange}
                                        />
                                            &nbsp;On
                                        </label>
                                </div>
                                <div className="col-3">
                                    <label>
                                        <input
                                            type="radio"
                                            value="off"
                                            checked={radio === "off"}
                                            onChange={onRadioChange}
                                        />
                                           &nbsp; Off
                                        </label>
                                </div>
                            </div> */}

                        <div className="card">
                            <div>
                                <div className="card-header">
                                    <h3 className="card-title">Frais de livraisons</h3>
                                    <div className="card-tools">
                                        <button onClick={loadTarif} className="btn btn-light btn-sm" title="Actualiser">
                                            <i className="fas fa-redo" />
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            {!isAddingTarif ? (
                                                <tr>
                                                    <th style={{ width: 50 }}>Tarifs</th>
                                                    <th style={{ width: 50 }}>

                                                        <button onClick={() => setIsAddingTarif(true)} className="btn btn-primary btn-sm" title="Créer">
                                                            <i className="fas fa-plus" />
                                                        </button>
                                                    </th>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="2">
                                                        <form onSubmit={submitAddTarifForm} >
                                                            <div className="row">
                                                                <input
                                                                    className="form-control col-7"
                                                                    type="number"
                                                                    name="nom"
                                                                    id="nom"
                                                                    autoFocus
                                                                    value={montant}
                                                                    onChange={montantChangeHandle}
                                                                    placeholder="Montant"
                                                                    required
                                                                />
                                                                <button type="submit" className="btn btn-primary btn-sm col-2" title="Créer">
                                                                    <i className="fas fa-save" />
                                                                    {isLoading && <img src={spinner} alt="Logo" style={{ height: 20 }} />}
                                                                </button>
                                                                <button className="btn btn-secondary btn-sm ml-2" onClick={annulerSaisieTarif}>
                                                                    <i className="fas fa-times" />
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </td>
                                                </tr>
                                            )}
                                        </thead>
                                        {!isLoading
                                            ? <Tbody data={tarifs} />
                                            :(
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src={spinner} alt="Logo" style={{ height: 100 }} />
                                                    </td>
                                                </tr>
                                            </tbody> 
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12">

                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ConfigurationDisplay
