import React, { Fragment, useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext';
import { useHistory, useParams } from "react-router-dom";

import spinner from '../images/spinner.gif'
import avatar from '../images/avatar.png'

const ProfilLivreur = () => {


    const history = useHistory()

    const { id_livreur } = useParams();
    const { param_etat } = useParams();

    const { user, apiUrlRoot } = useContext(UserContext);
    const [livreur, setLivreur] = useState();
    const [courses, setCourses] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    useEffect(() => {
        getLivreur();
        getLivreurCourses();
    }, [param_etat]);


    const navigate = (link) => {
        history.push(link);
    }

    const getLivreur = () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = apiUrlRoot+"admin/livreur/" + id_livreur;

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.code===201) {
                    setLivreur(result);
                } else {
                    alert('Une erreur est survenue, veuillez recharger la page');
                }
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }

    const getLivreurCourses = (ad) => {
        setIsLoading2(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            param = param_etat ? '/' + param_etat : '',
            url = ad ? ad : apiUrlRoot+"admin/livreur/" + id_livreur + "/courses" + param;

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading2(false);
                if (result) {
                    setCourses(result);
                } else {
                    alert('Une erreur est survenue, veuillez recharger la page');
                }
            },
                (error) => {
                    setIsLoading2(false);
                    console.error(error)
                }
            )
    }


    const EtatFormat = ({ etat }) => {
        if (etat === 3) { return <span style={{ padding: 10 }} className="badge bg-success">Terminée</span> }
        else if (etat === 2) { return <span style={{ padding: 10 }} className="badge bg-info">En attente</span> }
        else if (etat === 1) { return <span style={{ padding: 10 }} className="badge bg-warning">En cours</span> }
    }

    const DateFormat = ({ date }) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            heure = '' + d.getHours(),
            minute = '' + d.getMinutes();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if (heure.length < 2) heure = '0' + heure;
        if (minute.length < 2) minute = '0' + minute;

        return (
            <Fragment>
                {[day, month, year].join('/')} <br />{[heure, minute].join(':')}
            </Fragment>
        )
    }


    const Tbody = ({ data }) => {
        let i = 1, tr;
        if (data.data.length > 0) {
            tr = data.data.map((item) => (
                <tr key={(i++).toString()}>
                    <td><EtatFormat etat={item.etat} /></td>
                    <td><DateFormat date={item.updated_at} /></td>
                    <td>COL-{item.id}</td>
                    <td>{item.libelle}</td>
                    <td>{item.expedition} <br />Tel: {item.expediteur}</td>
                    <td>{item.destination} <br />Tel: {item.destinataire}</td>
                    <td>{item.tarif} F</td>
                </tr>
            )
            );
        } else {
            tr = (
                <Fragment>
                    <tr>
                        <td colSpan={7}></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td colSpan={3} style={{ height: 100 }}> <h4>Aucune courses à afficher</h4></td>
                    </tr>
                </Fragment>
            )
        }
        return (<tbody>{tr}</tbody>)

    }

    const DisplayPagination = ({ data }) => {
        return (
            <ul className="pagination pagination-sm float-right">
                { data.current_page !== 1 && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getLivreurCourses(data.first_page_url) }}>«</a></li>
                )}
                {data.prev_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getLivreurCourses(data.prev_page_url) }}>Précédent</a></li>
                )}
                <li className="page-item"><a className="page-link" href="#">{data.current_page}</a></li>

                {data.next_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getLivreurCourses(data.next_page_url) }}>Suivant</a></li>
                )}
                { data.current_page !== data.last_page && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getLivreurCourses(data.last_page_url) }} >»</a></li>
                )}

            </ul>
        )
    }

    return (
        <div className="content-wrapper" style={{ minHeight: '1624.75px' }}>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Profil Livreur</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#" onClick={() => { navigate('/') }}>Tableau de bord</a></li>
                                <li className="breadcrumb-item"><a href="#" onClick={() => { navigate('/livreur') }}>Utilisateur</a></li>
                                <li className="breadcrumb-item active">Profil</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            {(livreur && livreur.user && !isLoading) && (
                                <div>
                                    {/* Profile Image */}
                                    <div className="card card-primary card-outline">
                                        <div className="card-body box-profile">
                                            <div className="text-center">
                                                <img className="profile-user-img img-fluid img-circle" src={livreur.user.photo_id ? livreur.user.photo_id : avatar} alt="photo" />
                                            </div>
                                            <h3 className="profile-username text-center">{livreur.user.nom + ' ' + livreur.user.prenom}</h3>
                                            <p className="text-muted text-center">{livreur.user.role}</p>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                <li className="list-group-item" onClick={() => { navigate('/livreur/' + livreur.user.id + '/en-cours') }} >
                                                    <b>En cours</b> <a className="float-right">{livreur.stats.enCours}</a>
                                                </li>
                                                <li className="list-group-item" onClick={() => { navigate('/livreur/' + livreur.user.id + '/en-attente') }} >
                                                    <b>En attente</b> <a className="float-right">{livreur.stats.enAttente}</a>
                                                </li>
                                                <li className="list-group-item" onClick={() => { navigate('/livreur/' + livreur.user.id + '/terminee') }} >
                                                    <b>Terminées ce jour</b> <a className="float-right">{livreur.stats.termineCeJour}</a>
                                                </li>
                                                <li className="list-group-item" >
                                                    <b>Gagné ce jour</b> <a className="float-right">{livreur.stats.recetteDuJour} F.CFA</a>
                                                </li>
                                                <li className="list-group-item" onClick={() => { navigate('/livreur/' + livreur.user.id + '/terminee') }} >
                                                    <b>Total réalisé</b> <a className="float-right">{livreur.stats.termineTotal}</a>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                    {/* About Me Box */}
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">A propos</h3>
                                        </div>
                                        {/* /.card-header */}
                                        <div className="card-body">
                                            <strong><i className="fas fa-book mr-1" /> Contacts</strong>
                                            <p className="text-muted">
                                                {livreur.user.tel}
                                                {livreur.user.tel2 ? <p>{livreur.user.tel2}</p> : ''}
                                                {livreur.user.email ? <p>{livreur.user.email}</p> : ''}
                                            </p>
                                            <hr />
                                            <strong><i className="fas fa-map-marker-alt mr-1" /> Adresse</strong>
                                            <p className="text-muted">{livreur.user.domicile}</p>
                                            <hr />
                                            <strong><i className="fas fa-pencil-alt mr-1" /> References</strong>
                                            <p className="text-muted">
                                                Num CNI :<a href="#">{livreur.user.numcnib}</a>
                                            </p>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                            )}
                            {isLoading && (
                                <img src={spinner} alt="Logo" style={{ height: 80 }} />
                            )}

                            {/* /.card */}
                        </div>
                        {/* /.col */}
                        <div className="col-md-9">

                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        {param_etat
                                            ? param_etat.toUpperCase()
                                            : 'TOUTES LES COURSES'}
                                        &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;
                                        {courses && courses.from
                                            ? courses.from + '-' + courses.to + ' | total: ' + courses.total
                                            : ' '
                                        }
                                    </h3>
                                    {/* <div className="float-right">
                                        <div className="form-group">
                                            <label></label>
                                            <select className="select2" style={{ width: '100%' }} defaultValue="RECHERCHER">
                                                <option disabled >RECHERCHER</option>
                                                <option>DESC</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>

                                {/* /.card-header */}
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: 100 }}>Etat</th>
                                                <th>Date</th>
                                                <th>Code Colis</th>
                                                <th>Libelle</th>
                                                <th>Expediteur</th>
                                                <th>Destinataire</th>
                                                <th style={{ width: 90 }}>Tarif</th>
                                            </tr>
                                        </thead>
                                        {courses && !isLoading2
                                            ? <Tbody data={courses} />
                                            :(
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img src={spinner} alt="Logo" style={{ height: 100 }} />
                                                    </td>
                                                </tr>
                                            </tbody> 
                                        )}

                                    </table>
                                </div>
                                {/* /.card-body */}

                                <div className="card-footer">
                                    {courses
                                        ? <DisplayPagination data={courses} />
                                        : <img src={spinner} alt="spinner" style={{ height: 30 }} />
                                    }

                                </div>
                            </div>

                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>

    )
}

export default ProfilLivreur
