import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router';
import { UserContext } from '../context/UserContext';
import spinner from '../images/spinner.gif'

const AddLivreur = () => {

    const {user, apiUrlRoot, setUser} = useContext(UserContext);

    const [nom, setNom] = useState();
    const [prenom, setPrenom] = useState();
    const [phone, setPhone] = useState();
    const [phone2, setPhone2] = useState();
    const [cni, setCni] = useState();
    const [domicile, setDomicile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    
    const history = useHistory()



    const navigate = (link) => {
        history.push(link);
    }

    const submitForm = (e) => {
        e.preventDefault();
        register();
    }

    
    const register = () =>{
        setIsLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',  
                'Authorization': user.token_type+' '+user.access_token  
            },
            body: JSON.stringify({ 
                nom: nom,
                prenom: prenom,
                tel: phone,
                tel2: phone2,
                cni: cni,
                domicile: domicile
            })
        },
         url = apiUrlRoot+"admin/livreur/add";

        fetch(url,requestOptions)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoading(false);
              if(result.code===201){
                navigate('/livreur');
              } else if(result.tel) {
                setErrorMsg('Ce numéro de téléphone est déja utilisé');
              } else {
                setErrorMsg(result.message);
              }
            },
            (error) => {
              setIsLoading(false);
              console.error(error)
              setErrorMsg("Une erreur est survenue lors de votre requete. Vérifiez votre connexion internet");
            }
          )
    }

    const nomChangeHandle = event => {
        setNom(event.target.value);
    }

    const prenomChangeHandle = event => {
        setPrenom(event.target.value);
    }

    const phoneChangeHandle = event => {
        setPhone(event.target.value);
    }

    const phone2ChangeHandle = event => {
        setPhone2(event.target.value);
    }

    const cniChangeHandle = event => {
        setCni(event.target.value);
    }

    const domicileChangeHandle = event => {
        setDomicile(event.target.value);
    }

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="card card-primary col-md-7 offset-md-2">
                    <div className="card-header">
                        <h3 className="card-title">Nouveau Livreur</h3>
                        <div className="card-tools">
                            <button type="button" title="Annuler" className="btn btn-tool" onClick={()=> navigate('/livreur')}>
                                <i className="fas fa-times" />
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={submitForm}>
                            <div className="row">
                                <div className="form-group  col-5">
                                    <label htmlFor="nom">Nom <sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="nom"
                                        autoFocus
                                        value={nom}
                                        onChange={nomChangeHandle}
                                        required
                                    />
                                </div>
                                <div className="form-group col-7">
                                    <label htmlFor="prenom">Prénoms <sup className="text-danger">*</sup></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="prenom"
                                        id="prenom"
                                        value={prenom}
                                        onChange={prenomChangeHandle}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="telephone">Numéro de téléphone 1 <sup className="text-danger">*</sup></label>
                                    <input
                                        type="tel"
                                        id="telephone"
                                        className="form-control"
                                        maxLength="10"
                                        value={phone}
                                        onChange={phoneChangeHandle}
                                        required
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="telephone2">Téléphone 2 (facultatif)</label>
                                    <input
                                        type="tel"
                                        id="telephone2"
                                        className="form-control"
                                        maxLength="10"
                                        value={phone2}
                                        onChange={phone2ChangeHandle}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="cni">Numéro carte d'identité <sup className="text-danger">*</sup> </label>
                                <input
                                    type="text"
                                    id="cni"
                                    className="form-control"
                                    value={cni}
                                    onChange={cniChangeHandle}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="domicile">Adresse du domicile <sup className="text-danger">*</sup></label>
                                <input
                                    type="text"
                                    id="domicile"
                                    className="form-control"
                                    value={domicile}
                                    onChange={domicileChangeHandle}
                                    required
                                />
                            </div>
                            <p>NB: les champs marqué par (<sup className="text-danger">*</sup>) sont obligatoire</p>
                            <p className="login-box-msg text-danger">{errorMsg}</p>
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >Enregistrer &nbsp;
                                    {isLoading && (<img src={spinner} alt="Logo" style={{ height: 30 }} />)}
                                    </button>
                                </div>
                                {/* /.col */}
                            </div>
                        </form>
                    </div>
                    {/* /.card-body */}
                </div>
            </div>
        </div>
    )
}

export default AddLivreur
