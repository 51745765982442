import React, { Fragment, useEffect, useState } from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// import { Redirect } from 'react-router'

import Header from './component/Header';
import Menu from './component/Menu';
import Footer from './component/Footer';
import DashBord from './component/DashBord';
import { UserContext } from './context/UserContext';
import Login from './pages/Login';
import ErrorPage from './pages/ErrorPage';
import CourseDisplay from './component/CourseDisplay';
import LivreurDisplay from './component/LivreurDisplay';
import ProfilLivreur from './component/ProfilLivreur';
import AddLivreur from './component/AddLivreur';
import ConfigurationDisplay from './component/ConfigurationDisplay';
import JournalDisplay from './component/JournalDisplay';
import SuperMenu from './super-component/SuperMenu';
import SuperDashBord from './super-component/SuperDashBord';
import SuperEntreprise from './super-component/SuperEntreprise';
import SuperEntrepriseAdd from './super-component/SuperEntrepriseAdd';

const App = () => {

  // const history = useHistory();
  const [user, setUser] = useState('');
  // const apiUrlRoot = 'http://localhost:8000/';
  const apiUrlRoot = 'https://socolis-backend.expertizlab.com/';

  const getItem = () => {
    let data = sessionStorage.getItem('loginUserData');
    return JSON.parse(data);
  }

  useEffect(() => {

    // const script = document.createElement("script");
    // script.src = "dist/js/adminlte.js";
    // script.async = true;
    // document.body.appendChild(script);

    setUser(getItem());
    // console.log('restore data', getItem());
  }, [])

  return (
    <Router>
      <UserContext.Provider value={{ user, apiUrlRoot, setUser }}>
        {/* <div className="wrapper"> */}

          {(user && user.privilege === 'super-admin') && (
            <Fragment>
              {/* <Redirect to="/" /> */}
              <Header />
              <SuperMenu />
              <Switch>
                <Route exact path="/">
                  <SuperDashBord />
                </Route>
                <Route exact path="/entreprise">
                  <SuperEntreprise />
                </Route>
                <Route exact path="/entreprise/new">
                  <SuperEntrepriseAdd />
                </Route>
                <Route>
                  <ErrorPage />
                </Route>
              </Switch>
            </Fragment>
          )}

          {(user && user.privilege === 'admin') && (
            <Fragment>
              {/* <Redirect to="/" /> */}
              <Header />
              <Menu />
              <Switch>
                <Route exact path="/">
                  <DashBord />
                </Route>
                <Route exact path="/course">
                  <CourseDisplay />
                </Route>
                <Route path="/course/:param_etat">
                  <CourseDisplay />
                </Route>
                <Route path="/user/new">
                  <AddLivreur />
                </Route>
                <Route exact path="/livreur">
                  <LivreurDisplay />
                </Route>
                <Route exact path="/livreur/:id_livreur">
                  <ProfilLivreur />
                </Route>
                <Route path="/livreur/:id_livreur/:param_etat">
                  <ProfilLivreur />
                </Route>
                <Route path="/journal">
                  <JournalDisplay />
                </Route>
                <Route path="/config">
                  <ConfigurationDisplay />
                </Route>
                <Route>
                  <ErrorPage />
                </Route>
              </Switch>
            </Fragment>
          )}

          {!user && (
            <Fragment>
              {/* <Redirect to="/login" /> */}
              <Switch>
                {/* <Route path="/login">
                <Login />
              </Route> */}
                <Route>
                  <Login />
                </Route>
              </Switch>
            </Fragment>
          )}
          <Footer />

        {/* </div> */}
      </UserContext.Provider>
    </Router>
  )
}
export default App

