import React from 'react'

const ErrorPage = () => {

    return (
        <div className="content-wrapper">
            <h2 className="ml-5">Error page</h2>
            <p className="ml-5"> la page demandé n'existe pas </p>
        </div>
    )
}

export default ErrorPage
