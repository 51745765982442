import React, { useContext, useState } from 'react'
import { UserContext } from '../context/UserContext';
import { useHistory } from "react-router-dom";
// import logo from '../images/logo.svg'
import defaultLogo from '../images/defaultLogo.png'
import spinner from '../images/spinner.gif'

const Header = () => {

    const { user, apiUrlRoot, setUser } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory()



    const disconnect = () => {
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = apiUrlRoot+"admin/logout";

        fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log('result', result);
                    setIsLoading(false);
                    setUser('');
                    sessionStorage.clear();
                    history.push('/login');
                },
                (error) => {
                    setIsLoading(false);
                    console.error(error);
                    setUser('');
                    sessionStorage.clear();
                    history.push('/login');
                }
            )
    }


    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars" /></a>
                </li>
                <li className="nav-item">
                    {/* <a className="nav-link"> {user.access_token ? 'connecté' : 'non connecté'} </a> */}
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {user.access_token && (
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            {user.prenom} &nbsp;<i className="fas fa-power-off" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <a className="dropdown-item" onClick={disconnect}>
                                Deconnexion
                               {isLoading && ( <img src={spinner} style={{ height: 50 }} />)}
                            </a>
                            <div className="dropdown-divider" />
                        </div>
                    </li>
                )}
                <li className="nav-item d-none d-sm-inline-block">
                    <img src={user.entreprise_logo?apiUrlRoot+user.entreprise_logo:defaultLogo} alt="Logo" style={{ height: 50 }} />
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i className="fas fa-expand-arrows-alt" />
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Header
