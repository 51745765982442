import React, { useEffect, useContext, useState, Fragment } from 'react'
import { useHistory } from "react-router-dom";

import { UserContext } from '../context/UserContext';
import spinner from '../images/spinner.gif'
// import Header from './Header';
// import Menu from './Menu';

const DashBord = () => {

    const {user, apiUrlRoot} = useContext(UserContext);
    const [dashboardData, setDashboardData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('');
    const [courses, setCourses] = useState();
    const history = useHistory()


    const navigate = (link) => {
        history.push(link);
    }

    useEffect(() => {
        getCourses();
        loadDashbord();

        // setInterval(() => {
        //     loadDashbord();
        //     getCourses();
        // }, 10000);

    }, []);

    const getCourses = () => {
        // setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = apiUrlRoot+"admin/course-dashboard";

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                // setIsLoading(false);
                if (result.current_page) {
                    setCourses(result);
                }
            },
                (error) => {
                    // setIsLoading(false);
                    console.error(error)
                }
            )
    }

    const loadDashbord = () =>{
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',  
                'Authorization': user.token_type+' '+user.access_token  
            }
        },
         url = apiUrlRoot+"admin/dashboard";

        fetch(url,requestOptions)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoading(false);
              if(result.code === 201){
                setDashboardData(result.data);
              } else{
                setErrorMsg(result.message);
              }
            },
            (error) => {
              setIsLoading(false);
              console.error(error)
            }
          )
    }

    const EtatFormat = ({ etat }) => {
        if (etat === 3) { return <span style={{ padding: 10 }} className="badge bg-success">Terminée</span> }
        else if (etat === 2) { return <span style={{ padding: 10 }} className="badge bg-info">En attente</span> }
        else if (etat === 1) { return <span style={{ padding: 10 }} className="badge bg-warning">En cours</span> }
    }

    const DateFormat = ({ date }) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            heure = '' + d.getHours(),
            minute = '' + d.getMinutes();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if (heure.length < 2) heure = '0' + heure;
        if (minute.length < 2) minute = '0' + minute;

        return (
            <Fragment>
                {[day, month, year].join('/')} <br />{[heure, minute].join(':')}
            </Fragment>
        )
    }

    const Tbody = ({ data }) => {
        let i = 1, tr;
        if (data.data.length > 0) {
            
            tr = data.data.map((item) => (
                <tr key={(i++).toString()}>
                    <td><EtatFormat etat={item.etat} /></td>
                    <td><DateFormat date={item.updated_at} /></td>
                    <td>COL-{item.id}</td>
                    <td>
                        <a href="#" onClick={() => { navigate('/livreur/' + item.user_id) }}>
                            {item.user.nom + ' ' + item.user.prenom}
                        </a>
                    </td>
                </tr>
            )
            );
        } else {
            tr = (
                <Fragment>
                    <tr>
                        <td colSpan={4}></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan={2} style={{ height: 100 }}> <h4>Aucune courses à afficher</h4></td>
                    </tr>
                </Fragment>
            )
        }
        return (<tbody>{tr}</tbody>)
    }

    return (
        <div>
        {/* <Header />
        <Menu /> */}
    <div className="content-wrapper p-2">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Aujourd'hui</h1>
                    </div>
                </div>
            </div>
        </div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-6">
                        <div className="small-box bg-warning" onClick={()=>{navigate('/course/en-cours')}}>
                            <div className="inner">
                                <h3>
                                    { dashboardData
                                    ? dashboardData.enCours
                                    :(<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                    }
                                    &nbsp;<sup style={{ fontSize: 20 }}>colis</sup>
                                </h3>
                                <p>En cours de livraison</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag" />
                            </div>
                            <a className="small-box-footer">Voir la liste <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div className="small-box bg-info" onClick={()=>{navigate('/course/en-attente')}}>
                            <div className="inner">
                                <h3>
                                    { dashboardData
                                    ? dashboardData.enAttente
                                    :(<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                    }
                                    &nbsp; <sup style={{ fontSize: 20 }}>course(s)</sup></h3>
                                <p>En attente de retour</p>
                            </div>
                            <div className="icon">
                                <i className="ion " />
                            </div>
                            <a className="small-box-footer">Voir la liste <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-6">
                        <div className="small-box bg-secondary" onClick={()=>{navigate('/livreur')}}>
                            <div className="inner">
                                <h3>
                                    { dashboardData
                                    ? dashboardData.livreur
                                    :(<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                    }
                                    &nbsp;<sup style={{ fontSize: 20 }}>coursier(s)</sup></h3>
                                <p>En exercice</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-person" />
                            </div>
                            <a className="small-box-footer">Voir la liste <i className="fas fa-arrow-circle-right" /></a>
                        </div>
                    </div>
                </div>
                {/* /.row */}
                {/* Main row */}
                <div className="row">
                    {/* Left col */}
                    <section className="col-lg-7">

            
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        COURSES RECENTES
                    </h3>
                    <div className="float-right">
                        
                    </div>
                </div>
                <div className="card-body p-0">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Etat</th>
                                <th>Date</th>
                                <th>Code Colis</th>
                                <th>Coursier</th>
                            </tr>
                        </thead>
                        {courses 
                            ? <Tbody data={courses} />
                            :(
                            <tbody>
                                <tr>
                                    <td>
                                        <img src={spinner} alt="Logo" style={{ height: 100 }} />
                                    </td>
                                </tr>
                            </tbody> 
                        )}

                    </table>
                </div>
            </div>
                    </section>


                    <div className="col-lg-5">
                        {/* small box */}
                        <div className="small-box bg-success" onClick={()=>{navigate('/course/terminee')}} >
                            <br/>
                            <div className="inner">
                                <h1>
                                    { dashboardData
                                    ? dashboardData.termine
                                    :(<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                    }
                                    &nbsp;<sup style={{ fontSize: 20 }}>Courses</sup>
                                </h1>
                                <h4>Réalisées Aujourd'hui</h4>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag" />
                            </div> <br/>
                            <a className="small-box-footer">Voir la liste <i className="fas fa-arrow-circle-right" /></a>
                        </div>

                        <div className="small-box bg-danger" >
                            <br/>
                            <div className="inner">
                                <h4>Recette du jour</h4>
                                <h1>
                                    { dashboardData
                                    ? dashboardData.recette
                                    :(<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                    }
                                    &nbsp;<sup style={{ fontSize: 20 }}>F.CFA</sup>
                                </h1>
                            </div>
                            <div className="icon">
                                <i className="ion ion-cash" />
                            </div>
                            <br/><br/>
                        </div>
                    </div>

                    {/* right col */}
                </div>
                {/* /.row (main row) */}
            </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
    </div>
    </div>
    )
}

export default DashBord
