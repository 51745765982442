import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { UserContext } from '../context/UserContext';
import spinner from '../images/spinner.gif'

const SuperEntrepriseAdd = () => {

    const { user, apiUrlRoot, setUser } = useContext(UserContext);

    const [nomEntreprise, setNomEntreprise] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [cni, setCni] = useState('');
    const [adresse, setAdresse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [email, setEmail] = useState('');
    const [pays, setPays] = useState('');
    const [selectedPays, setSelectedPays] = useState('');


    const history = useHistory()


    useEffect(() => {
        loadPays();
    }, [])

    const navigate = (link) => {
        history.push(link);
    }

    const submitForm = (e) => {
        e.preventDefault();
        register();
    }


    const loadPays = () => {
        setIsLoading2(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = apiUrlRoot + "admin/pays";

        fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoading2(false);
                    if (result.code === 201) {
                        setPays(result.data);
                    } else {
                        setErrorMsg(result.message);
                    }
                },
                (error) => {
                    setIsLoading2(false);
                    console.error(error)
                    setErrorMsg("Une erreur est survenue lors de votre requete. Vérifiez votre connexion internet");
                }
            )
    }

    const register = () => {
        setIsLoading(true);
        setErrorMsg('')
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            },
            body: JSON.stringify({
                nomEntreprise: nomEntreprise,
                tel: phone,
                tel2: phone2,
                email: email,
                adresse: adresse,
                cni: cni,
                nom: nom,
                prenom: prenom,
                pays: selectedPays,
            })
        },
            url = apiUrlRoot + "admin/entreprise/add";

        fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoading(false);
                    if (result.code === 201) {
                        navigate('/entreprise');
                    } else if (result.tel) {
                        setErrorMsg('Ce numéro de téléphone est déja utilisé');
                    } else {
                        setErrorMsg(result.message);
                    }
                },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                    setErrorMsg("Une erreur est survenue lors de votre requete. Vérifiez votre connexion internet");
                }
            )
    }


    const nomEntrepriseChangeHandle = event => {
        setNomEntreprise(event.target.value);
    }

    const nomChangeHandle = event => {
        setNom(event.target.value);
    }

    const prenomChangeHandle = event => {
        setPrenom(event.target.value);
    }

    const phoneChangeHandle = event => {
        setPhone(event.target.value);
    }

    const phone2ChangeHandle = event => {
        setPhone2(event.target.value);
    }

    const cniChangeHandle = event => {
        setCni(event.target.value);
    }

    const adresseChangeHandle = event => {
        setAdresse(event.target.value);
    }

    const emailChangeHandle = event => {
        setEmail(event.target.value);
    }

    const paysChangeHandler = event => {
        setSelectedPays(event.target.value);
    }

    const SelectPays = ({data}) => {
        let option = '';
        if(data){
            option = data.map((item) => (
                <option
                    key={(item.id).toString()}
                    value={item.id}
                >
                    {item.nom}
                </option>
            ))}
        return (
        <select
        className="form-control"
        value={selectedPays}
        onChange={paysChangeHandler}
        required
    >
        <option disabled value="" >Choisir</option>
        {option}
    </select>)
    }

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="card card-primary col-md-7 offset-md-2">
                    <div className="card-header">
                        <h3 className="card-title">Création Entreprise</h3>
                        <div className="card-tools">
                            <button type="button" title="Annuler" className="btn btn-tool" onClick={() => navigate('/entreprise')}>
                                <i className="fas fa-times" />
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={submitForm}>

                            <p>NB: les champs marqué par (<sup className="text-danger">*</sup>) sont obligatoire</p>

                            <fieldset style={{ border: 'solid 1px #ccc', padding: 10 }}>
                                <legend>Entreprise</legend>

                                <div className="row">
                                    <div className="form-group  col-7">
                                        <label htmlFor="entreprise">Nom <sup className="text-danger">*</sup></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="entreprise"
                                            autoFocus
                                            value={nomEntreprise}
                                            onChange={nomEntrepriseChangeHandle}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <label htmlFor="telephone">Numéro de téléphone 1 <sup className="text-danger">*</sup></label>
                                        <input
                                            type="telephone"
                                            id="telephone"
                                            name="telephone"
                                            className="form-control"
                                            maxLength="10"
                                            value={phone}
                                            onChange={phoneChangeHandle}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="telephone2">Téléphone 2 (facultatif)</label>
                                        <input
                                            type="tel"
                                            id="telephone2"
                                            name="telephone2"
                                            className="form-control"
                                            maxLength="10"
                                            value={phone2}
                                            onChange={phone2ChangeHandle}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group  col-6">
                                        <label htmlFor="nom">Email <sup className="text-danger">*</sup></label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="nom"
                                            id="nom"
                                            value={email}
                                            onChange={emailChangeHandle}
                                            required
                                        />
                                    </div>
                                    <div className="form-group  col-5">
                                        <label htmlFor="pays">Pays <sup className="text-danger">*</sup></label>

                                            {isLoading2 
                                            ? (<img src={spinner} alt="Logo" style={{ height: 30 }} />)
                                            :  <SelectPays data={pays} /> }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-12">
                                        <label htmlFor="adresse">Adresse <sup className="text-danger">*</sup></label>
                                        <input
                                            type="text"
                                            id="adresse"
                                            className="form-control"
                                            value={adresse}
                                            onChange={adresseChangeHandle}
                                            required
                                        />
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset style={{ border: 'solid 1px #ccc', padding: 10 }}>
                                <legend>Gérant</legend>
                                <div className="row">
                                    <div className="form-group  col-5">
                                        <label htmlFor="nom">Nom <sup className="text-danger">*</sup></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="nom"
                                            id="nom"
                                            autoFocus
                                            value={nom}
                                            onChange={nomChangeHandle}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-7">
                                        <label htmlFor="prenom">Prénoms <sup className="text-danger">*</sup></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="prenom"
                                            id="prenom"
                                            value={prenom}
                                            onChange={prenomChangeHandle}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="cni">Numéro carte d'identité </label>
                                    <input
                                        type="text"
                                        id="cni"
                                        name="cni"
                                        className="form-control"
                                        maxLength="20"
                                        value={cni}
                                        onChange={cniChangeHandle}
                                    />
                                </div>
                                <p className="login-box-msg text-danger">{errorMsg}</p>
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                        >Enregistrer &nbsp;
                                    {isLoading && (<img src={spinner} alt="Logo" style={{ height: 30 }} />)}
                                        </button>
                                    </div>
                                    {/* /.col */}
                                </div>

                            </fieldset>
                        </form>
                    </div>
                    {/* /.card-body */}
                </div>
            </div>
        </div>
    )
}

export default SuperEntrepriseAdd
