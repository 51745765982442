import React, { useContext, useEffect, useState, Fragment } from 'react'

import spinner from '../images/spinner.gif'
import { UserContext } from '../context/UserContext';
import { useHistory, useParams } from 'react-router-dom';
// import Header from './Header';
// import Menu from './Menu';

const CourseDisplay = () => {

    const { user, apiUrlRoot } = useContext(UserContext);
    const [courses, setCourses] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [dateJournal, setDateJournal] = useState();
    const [displayMode, setDisplayMode] = useState('default');

    const history = useHistory()
    const { param_etat } = useParams();

    useEffect(() => {
        // const script = document.createElement("script");
        // script.src = "js/select2Init.js";
        // script.async = true;
        // document.body.appendChild(script);

        setDateJournal('');
        getCourses();
    }, [param_etat])


    const navigate = (link) => {
        history.push(link);
    }

    const submitSearchForm = (e) => {
        e.preventDefault();
        setDisplayMode('byDate');
        getCoursesByDate();
    }

    const resetSearchForm = (e) => {
        e.preventDefault();
        if(displayMode ==='byDate'){
            getCourses();
            setDateJournal('');
            setDisplayMode('default');
        } 
    }

    const getCoursesByDate = (ad) => {
        setIsLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            },
            body: JSON.stringify({ 
                'requestDate': dateJournal,
            })
        },
            param = param_etat ? '/' + param_etat : '',
            url = ad ? ad : apiUrlRoot+"admin/courseByDate" + param;

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.current_page) {
                    setCourses(result);
                }
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }

    const getCourses = (ad) => {
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            },
            // body: JSON.stringify({ 
            //     'requestDate': dateJournal,
            // })
        },
            param = param_etat ? '/' + param_etat : '',
            url = ad ? ad : apiUrlRoot+"admin/course" + param;

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.current_page) {
                    setCourses(result);

                }
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }

    const EtatFormat = ({ etat }) => {
        if (etat === 3) { return <span style={{ padding: 10 }} className="badge bg-success">Terminée</span> }
        else if (etat === 2) { return <span style={{ padding: 10 }} className="badge bg-info">En attente</span> }
        else if (etat === 1) { return <span style={{ padding: 10 }} className="badge bg-warning">En cours</span> }
    }

    const dateChangeHandle = (event) =>{
        setDateJournal(event.target.value);
    }

    // const getDate = () => {
    //     var d = new Date(),
    //         month = '' + (d.getMonth() + 1),
    //         day = '' + d.getDate(),
    //         year = d.getFullYear();

    //     if (month.length < 2) month = '0' + month;
    //     if (day.length < 2) day = '0' + day;

    //     return [ year,month,day].join('-')
    // }

    const DateFormat = ({ date }) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            heure = '' + d.getHours(),
            minute = '' + d.getMinutes();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if (heure.length < 2) heure = '0' + heure;
        if (minute.length < 2) minute = '0' + minute;

        return (
            <Fragment>
                {[day, month, year].join('/')} <br />{[heure, minute].join(':')}
            </Fragment>
        )
    }


    const Tbody = ({ data }) => {
        let i = 1, tr;
        if (data.data.length > 0) {
            tr = data.data.map((item) => (
                <tr key={(i++).toString()}>
                    <td><EtatFormat etat={item.etat} /></td>
                    <td><DateFormat date={item.updated_at} /></td>
                    <td>COL-{item.id}</td>
                    <td>{item.libelle}</td>
                    <td>{item.expedition} <br />Tel: {item.expediteur}</td>
                    <td>{item.destination} <br />Tel: {item.destinataire}</td>
                    <td>
                        <a href="#" onClick={() => { navigate('/livreur/' + item.user_id) }}>
                            {item.user.nom + ' ' + item.user.prenom}
                        </a>
                    </td>
                    <td>{item.tarif} F</td>
                </tr>
            )
            );
        } else {
            tr = (
                <Fragment>
                    <tr>
                        <td colSpan={7}></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td colSpan={3} style={{ height: 100 }}> <h4>Aucune courses à afficher</h4></td>
                    </tr>
                </Fragment>
            )
        }
        return (<tbody>{tr}</tbody>)

    }

    const DisplayPaginationByDate = ({ data }) => {
        return (
            <ul className="pagination pagination-sm float-right">
                { data.current_page !== 1 && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCoursesByDate(data.first_page_url) }}>«</a></li>
                )}
                {data.prev_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCoursesByDate(data.prev_page_url) }}>Précédent</a></li>
                )}
                <li className="page-item"><a className="page-link" href="#">{data.current_page}</a></li>

                {data.next_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCoursesByDate(data.next_page_url) }}>Suivant</a></li>
                )}
                { data.current_page !== data.last_page && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCoursesByDate(data.last_page_url) }} >»</a></li>
                )}

            </ul>
        )
    }

    const DisplayPagination = ({ data }) => {
        return (
            <ul className="pagination pagination-sm float-right">
                { data.current_page !== 1 && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCourses(data.first_page_url) }}>«</a></li>
                )}
                {data.prev_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCourses(data.prev_page_url) }}>Précédent</a></li>
                )}
                <li className="page-item"><a className="page-link" href="#">{data.current_page}</a></li>

                {data.next_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCourses(data.next_page_url) }}>Suivant</a></li>
                )}
                { data.current_page !== data.last_page && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getCourses(data.last_page_url) }} >»</a></li>
                )}

            </ul>
        )
    }

    return (
        <div>

        {/* <Header />
        <Menu /> */}
        <div className="content-wrapper">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        {param_etat
                            ? param_etat.toUpperCase()
                            : 'TOUTES LES COURSES'}
                        &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;
                        {courses && courses.from
                            ? courses.from + '-' + courses.to + ' | total: ' + courses.total
                            : ' '
                        }
                    </h3>
                    <div className="float-right">
                        <form onSubmit={submitSearchForm} onReset={resetSearchForm}>
                            <label htmlFor="recherche">Trier par date: </label>
                            <input 
                            type="date" 
                            name="recherche" 
                            id="recherche"
                            value={dateJournal}
                            onChange={dateChangeHandle}
                            required
                            />
                            <button type="submit" className="btn btn-primary ml-2">Trier</button>
                            <button type="reset" className="btn btn-primary btn-secondary ml-2">
                            <i className="fas fa-times" />
                            </button>
                        </form>
                    </div>
                </div>



                {/* /.card-header */}
                <div className="card-body p-0">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{ width: 100 }}>Etat</th>
                                <th>Date</th>
                                <th>Code Colis</th>
                                <th>Libelle</th>
                                <th>Expediteur</th>
                                <th>Destinataire</th>
                                <th>Coursier</th>
                                <th style={{ width: 90 }}>Tarif</th>
                            </tr>
                        </thead>
                        {courses && !isLoading
                            ? <Tbody data={courses} />
                            :(
                            <tbody>
                                <tr>
                                    <td>
                                        <img src={spinner} alt="Logo" style={{ height: 100 }} />
                                    </td>
                                </tr>
                            </tbody> 
                        )}

                    </table>
                </div>
                {/* /.card-body */}

                <div className="card-footer">
                    {courses && displayMode==='default' && (
                        <DisplayPagination data={courses} />
                    )}
                    {courses && displayMode==='byDate' && (
                        <DisplayPaginationByDate data={courses} />
                    )}
                    {isLoading && (
                        <img src={spinner} alt="spinner" style={{ height: 30 }} />
                    )}

                </div>
            </div>
        </div>
        </div>
    )
}

export default CourseDisplay
