import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <footer className="main-footer">
                <strong>Copyright © 2020-2021 <a target="blank" href="https://digital-co.ci">Digital-co.ci</a>.</strong> All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>... | Burkina Faso | Côte d'Ivoire</b>
                </div>
            </footer>

        )
    }
}
