import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import spinner from '../images/spinner.gif'

import { UserContext } from '../context/UserContext';

const Login = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [isConform, setIsConform] = useState();
    const [errorMsg, setErrorMsg] = useState();
    const [alertMsg, setAlertMsg2] = useState({color:'', msg:''});
    const [alertMsg3, setAlertMsg3] = useState({color:'', msg:''})
    const [isFirstconnect, setIsFirstconnect] = useState(false);
    const [tmpUser, setTmpUser] = useState();

    const { user, apiUrlRoot, setUser } = useContext(UserContext);

    const history = useHistory()


    useEffect(() => {

    }, [])

    const login = (phone, password) => {
        setIsLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': user.token_type+' '+user.access_token  
            },
            body: JSON.stringify({
                tel: phone,
                password: password
            })
        },
            url = apiUrlRoot+"admin/login";

        fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoading(false);
                    if (result.access_token) {
                        if (result.firstconnect === 1) {
                            setIsFirstconnect(true);
                            setTmpUser(result);
                        } else {
                            setUser(result);
                            setPassword('');
                            sessionStorage.setItem('loginUserData',JSON.stringify(result));
                            history.replace('/');
                        }
                    } else {
                        setErrorMsg(result.message);
                    }
                },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                    setErrorMsg("Une erreur est survenue lors de votre requete. Vérifiez votre connexion internet");
                }
            )
    }

    const updatePassword = () => {
        setIsLoading2(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tmpUser.token_type+' '+tmpUser.access_token  
            },
            body: JSON.stringify({
                password: newPassword
            })
        },
            url = apiUrlRoot+"admin/password-update";

        fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoading2(false);
                    if (result.code === 201) {
                        setErrorMsg('');
                        setAlertMsg3({color: 'success', msg: result.message});
                        setIsFirstconnect(false);
                        setPassword('');
                        setNewPassword('');
                        setNewPasswordConfirm('');
                    } else {
                        setAlertMsg2({color: 'danger', msg: result.message});
                    }
                },
                (error) => {
                    setIsLoading2(false);
                    setAlertMsg2({color: 'danger', msg: 'Une erreur est survenue lors de votre requete. Vérifiez votre connexion internet'});
                    console.error(error)
                }
            )
    }


    const disconnect = () => {
        setIsLoading3(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = apiUrlRoot+"admin/logout";

        fetch(url, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log('result', result);
                    setIsLoading3(false);
                    setUser('');
                    sessionStorage.clear();
                    history.push('/login');
                },
                (error) => {
                    setIsLoading3(false);
                    console.error(error)
                    setUser('');
                    sessionStorage.clear();
                    history.push('/login');
                }
            )
    }

    const submitLoginForm = (e) => {
        e.preventDefault();
        login(phone, password);
    }

    const submitPasswordForm = (e) => {
        e.preventDefault();
        updatePassword();
    }

    const phoneChangeHandle = event => {
        setPhone(event.target.value);
    }
    const passwordChangeHandle = event => {
        setPassword(event.target.value);
    }

    const newPasswordChangeHandle = event => {
        setNewPassword(event.target.value);
        if(event.target.value !== newPassword ){
            setAlertMsg2({color:'danger',msg:'Mot de passes non conformes'})
            setIsConform(false);
        } else {
            setIsConform(true);
            setAlertMsg2({color:'dark',msg:'Mot de passes conformes'});
        }
    }
    const newPasswordConfirmChangeHandle = event => {
        setNewPasswordConfirm(event.target.value)
        if(event.target.value !== newPassword ){
            setAlertMsg2({color:'danger',msg:'Mot de passes non conformes'})
            setIsConform(false);
        } else {
            setIsConform(true);
            setAlertMsg2({color:'dark',msg:'Mot de passes conformes'});
        }

    }



    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <b>Faso Colis</b>
                </div>
                {isFirstconnect ? (
                    <div className="card">
                        <div className="card-body login-card-body">
                            <h4 className="text-danger text-center">ATTENTION !!!</h4>
                            <p className="login-box-msg">Vous devez remplacer votre mot de passe par défaut.
                </p>
                            <small className="text-primary">Choississez un nouveau mot de passe que vous serez le seul à connaitre.</small>
                            <form onSubmit={submitPasswordForm}>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Nouveau Mot de passe"
                                        value={newPassword}
                                        onChange={newPasswordChangeHandle}
                                        name="password"
                                        required />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Confirmation du Mot de passe"
                                        value={newPasswordConfirm}
                                        onChange={newPasswordConfirmChangeHandle}
                                        name="passwordConfirm"
                                        required />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className={'login-box-msg text-'+alertMsg.color}>{alertMsg.msg}</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="btn btn-success btn-block"
                                            disabled={!isConform}
                                        >Modifier &nbsp;
                            {isLoading2 && (<img src={spinner} alt="Logo" style={{ height: 30 }} />)}
                                        </button>
                                        <button
                                            onClick={disconnect}
                                            className="btn btn-secondary btn-sm mt-4"
                                        > Déconnexion &nbsp;
                            {isLoading3 && (<img src={spinner} alt="Logo" style={{ height: 20 }} />)}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (

                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Connectez-vous </p>
                            <p className={'login-box-msg text-'+alertMsg3.color}>{alertMsg3.msg}</p>
                            <form onSubmit={submitLoginForm}>
                                <div className="input-group mb-3">
                                    <input
                                        autoFocus
                                        type="tel"
                                        className="form-control"
                                        placeholder="Téléphone"
                                        maxLength="10"
                                        value={phone}
                                        onChange={phoneChangeHandle}
                                        name="phone"
                                        required />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-user" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Mot de passe"
                                        value={password}
                                        onChange={passwordChangeHandle}
                                        name="password"
                                        required />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <p className="login-box-msg text-danger">{errorMsg}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block"
                                        >Connexion &nbsp;
                            {isLoading && (<img src={spinner} alt="Logo" style={{ height: 30 }} />)}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>

    )
}

export default Login
