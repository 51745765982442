import React, { useContext, useEffect, useState, Fragment } from 'react'

import { UserContext } from '../context/UserContext';
import { useHistory } from "react-router-dom";

import spinner from '../images/spinner.gif'
import defaultLogo from '../images/defaultLogo.png'

const SuperEntreprise = () => {


    const { user, apiUrlRoot } = useContext(UserContext);
    const [entreprises, setEntreprises] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory()


    useEffect(() => {
        const script = document.createElement("script");
        script.src = "js/select2Init.js";
        script.async = true;
        document.body.appendChild(script);
        getEntreprise();
    }, [])

    const navigate = (link) => {
        history.push(link);
    }

    const getEntreprise = (ad) => {
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.token_type + ' ' + user.access_token
            }
        },
            url = ad ? ad : apiUrlRoot+"admin/entreprise";

        fetch(url, requestOptions).then(res => res.json())
            .then((result) => {
                setIsLoading(false);
                if (result.current_page) {
                    setEntreprises(result);
                }
            },
                (error) => {
                    setIsLoading(false);
                    console.error(error)
                }
            )
    }


    const Tbody = ({ data }) => {
        let i = 1, tr;
        if (data.data.length > 0) {
            tr = data.data.map((item) => (
                <tr key={(i++).toString()}>
                    <td>
                        <img style={{ height: 50, borderRadius: 30 }} src={item.logo?apiUrlRoot+item.logo:defaultLogo} alt="logo" />
                    </td>
                    <td> <a href="#" onClick={() => { navigate('entreprise/'+item.id) }}>{item.nom }</a></td>
                    <td>{item.admin[0].nom } {item.admin[0].prenom }</td>
                    <td>
                        {item.phone}
                    </td>
                    <td>{item.email}</td>
                </tr>
            )
            );
        } else {
            tr = (
                <Fragment>
                    <tr>
                        <td colSpan={4}></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan={3} style={{ height: 100 }}> <h4>La liste est vide </h4></td>
                    </tr>
                </Fragment>
            )
        }
        return (<tbody>{tr}</tbody>)

    }

    const DisplayPagination = ({ data }) => {
        return (
            <ul className="pagination pagination-sm float-right">
                { data.current_page !== 1 && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getEntreprise(data.first_page_url) }}>«</a></li>
                )}
                {data.prev_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getEntreprise(data.prev_page_url) }}>Précédent</a></li>
                )}
                <li className="page-item"><a className="page-link" href="#">{data.current_page}</a></li>

                {data.next_page_url && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getEntreprise(data.next_page_url) }}>Suivant</a></li>
                )}
                { data.current_page !== data.last_page && (
                    <li className="page-item"><a className="page-link" href="#" onClick={() => { getEntreprise(data.last_page_url) }} >»</a></li>
                )}

            </ul>
        )
    }

    return (
        <div className="content-wrapper">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        LISTE DES ENTREPRISES
                        &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;
                        {entreprises && entreprises.from
                            ? entreprises.from + '-' + entreprises.to + ' | total: ' + entreprises.total
                            : ' '
                        }
                        <button type="button" className="btn btn-primary ml-5" onClick={() => { navigate('entreprise/new') }}>
                            <i className="fas fa-plus mr-2" />
                            Ajouter une entreprise
                        </button>
                    </h3>
                    {/* <div className="float-right">
                        <div className="form-group">
                            <label></label>
                            <select className="select2" style={{ width: '100%' }} defaultValue="RECHERCHER">
                                <option disabled >RECHERCHER</option>
                                <option>DESC</option>
                            </select>
                        </div>
                    </div> */}
                </div>

                {/* /.card-header */}
                <div className="card-body p-0">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>####</th>
                                <th>Nom</th>
                                <th>Gérant</th>
                                <th>Téléphone</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        {entreprises && !isLoading
                            ? <Tbody data={entreprises} />
                            :(
                            <tbody>
                                <tr>
                                    <td>
                                        <img src={spinner} alt="Logo" style={{ height: 100 }} />
                                    </td>
                                </tr>
                            </tbody> 
                        )}

                    </table>
                </div>
                {/* /.card-body */}

                <div className="card-footer">
                    {entreprises
                        ? <DisplayPagination data={entreprises} />
                        : <img src={spinner} alt="Logo" style={{ height: 30 }} />
                    }

                </div>
            </div>
        </div>
    )
}

export default SuperEntreprise